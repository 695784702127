
var ztrack_domain = new URLSearchParams(window.location.search).get('_d')

//tracking
var wrapUrlWithClickId=(function(){"use strict";function n(n,r){var e;void 0===r&&(r="uclick");var u=null===(e=n.match(/\?.+?$/))||void 0===e?void 0:e[0];return u?Array.from(u.matchAll(new RegExp("[?&](clickid|"+r+")=([^=&]*)","g"))).map((function(n){return{name:n[1],value:n[2]}})):[]}function r(n){var r=n();return 0===r.length?{}:r.reduce((function(n,r){var e;return Object.assign(n,((e={})[r.name]=""+r.value,e))}),{})}function e(e){void 0===e&&(e="uclick");var u,i,t=r((function(){return(function(n){return void 0===n&&(n="uclick"),Array.from(document.cookie.matchAll(new RegExp("(?:^|; )(clickid|"+n+")=([^;]*)","g"))).map((function(n){return{name:n[1],value:n[2]}}))})(e)})),c=r((function(){return n(document.referrer,e)})),a=r((function(){return n(document.location.search,e)}));return(u=[e,"clickid"],i=[t,c,a],u.reduce((function(n,r){return n.concat(i.map((function(n){return[r,n]})))}),[])).map((function(n){return{name:n[0],value:n[1][n[0]]}})).find((function(n){return n.value}))||null}function u(n,r,e){var u=n.replace(new RegExp(r+"=[^=&]*","g"),r+"="+e);return-1!==u.indexOf(r)?u:(function(n,r,e){var u=n.trim(),i=r+"="+e;return-1===u.indexOf("?")?u+"?"+i:u.endsWith("?")?""+u+i:u+"&"+i})(n,r,e)}return function(n,r){void 0===r&&(r="uclick");var i=e(r);return null===i?n:n.includes("cnv_id")?i.name===r?u(n,i.name,i.value):i.value?u(n,"cnv_id",i.value):n:u(n,i.name,i.value)}})();

var scroll = 0;
window.onscroll = function() {
    if(scroll != 1) {
        scroll = 1;
        var img = document.createElement('img');
        img.src = wrapUrlWithClickId('https://'+ztrack_domain+'/click.php?event1=1')
        img.referrerPolicy = 'no-referrer-when-downgrade'
    }
}

function pushToTrackerViaImage(){
    var img = document.createElement('img')
    img.src = wrapUrlWithClickId('https://'+ztrack_domain+'/click.php?event7=1')
    img.referrerPolicy = 'no-referrer-when-downgrade'
    img.style.display = 'none'
    document.body.appendChild(img)
}
function pushAfterTimeout(timeout) {
    setTimeout(pushToTrackerViaImage, timeout*1000)
}

var TIMEOUT_IN_SECONDS = 20
pushAfterTimeout(TIMEOUT_IN_SECONDS)