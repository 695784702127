// require('./fb-pixel.js')
require('../../assets/tracking.js')

// const md5Regex = require('md5-regex')
// var stringM = `
// fdf50a52bdc2272239af671eda57ef44 lkjshd lksjdhf sldkjfh 
// `

// var objM = stringM.match(md5Regex())

// function replaceAll(str,mapObj){
//   var re = new RegExp(Object.keys(mapObj).join("|"),"gi")

//   return str.replace(re, function(matched){
//       return mapObj[matched.toLowerCase()]
//   })
// }

// objM = {
//   "a04b3740a65833d6859a76e70c8caaaa": "AAA",
//   "9d742fc91c31ece47888abcc8e46a600": "BBB"
// }

// console.log(replaceAll(stringM,objM))

import { QA, QA_translations } from './qa'
import { traslations } from './traslations'
import '../../assets/import-jquery.js'

// Current Lang
var currentLanguage = document.getElementsByTagName('html')[0].lang

try {
  var currentLocale = new URLSearchParams(window.location.search).get('language').split('-')[0]
  currentLocale = currentLocale.replace(/[^a-zA-Z]+/g, '').toLowerCase()
  document.getElementsByTagName('html')[0].lang = currentLocale

  currentLanguage = document.getElementsByTagName('html')[0].lang
  if (QA_translations[currentLanguage] && traslations[currentLanguage]) {
    Object.keys(traslations[currentLanguage]).forEach((key) => {
      $(`[data-i18n=${key}]`).html(traslations[currentLanguage][key])
    })

    var passed_translations = true
  }
} catch { }


// set href for all .cta-btn
var currentDomain = new URLSearchParams(window.location.search).get('_d')
document.querySelectorAll('a.cta-btn').forEach(elem => {
  elem.href = 'https://' + currentDomain + '/click.php?lp=1'
  elem.target = '_blank'
})

// Set Welcome Image
try {
  if(new URLSearchParams(window.location.search).get('t4').startsWith('de.562.1')){
    $('#welcome-img').addClass(`s1 i${Math.floor(Math.random() * 4) + 1}`)
  }
} catch {}

// Set Welcome Image #2
try {
  if(new URLSearchParams(window.location.search).get('_sv').startsWith('s3-i1')){
    $('#welcome-img').addClass(`s3`)
  }
} catch {}

// Set Welcome Image #3
try {
  var sv_param = new URLSearchParams(window.location.search).get('_sv')
  if(sv_param.startsWith('s4-i1')){
    $('#welcome-img').addClass(`s4 i1`)
  }
} catch {}

try {
  var sv_param = new URLSearchParams(window.location.search).get('_sv')
  if(sv_param.startsWith('s4-i2')){
    $('#welcome-img').addClass(`s4 i2`)
  }
} catch {}


try {
  if(new URLSearchParams(window.location.search).get('_sv').startsWith('s4-i4')){
    $('#welcome-img').addClass(`s4 i4`)
  }
} catch {}

try {
  if(new URLSearchParams(window.location.search).get('_sv').startsWith('s4-i5')){
    $('#welcome-img').addClass(`s4 i5`)
  }
} catch {}

try {
  if(new URLSearchParams(window.location.search).get('_sv').startsWith('s4-i6')){
    $('#welcome-img').addClass(`s4 i6`)
  }
} catch {}

try {
  if(new URLSearchParams(window.location.search).get('_sv').startsWith('s4-i7')){
    $('#welcome-img').addClass(`s4 i7`)
  }
} catch {}

try {
  if(new URLSearchParams(window.location.search).get('_sv').startsWith('s4-i8')){
    $('#welcome-img').addClass(`s4 i8`)
  }
} catch {}

try {
  if(new URLSearchParams(window.location.search).get('_sv').startsWith('s4-i16')){
    $('#welcome-img').addClass(`s4 i16`)
  }
} catch {}

try {
  if(new URLSearchParams(window.location.search).get('_sv').startsWith('s4-i')){
    var ssv = new URLSearchParams(window.location.search).get('_sv').split('-').slice(-1)
    $('#welcome-img').addClass(`s4 ${ssv}`)
  }
} catch {}

try {
  if(new URLSearchParams(window.location.search).get('_sv').startsWith('s4-i1')){
    $('#welcome-img').addClass(`s4 i9`)
  }
} catch {}



// TB if needed
var tBackKey = new URLSearchParams(window.location.search).get('_tb')
var t2 = new URLSearchParams(window.location.search).get('campaign')
var t1 = new URLSearchParams(window.location.search).get('trafficsource')
var t9 = new URLSearchParams(window.location.search).get('t9')

if (currentDomain && tBackKey) {
  $('a.cta-btn').on('click', function (e) {
    $(window).on('blur', function () {
      $(window).on('focus', function () {
        $('.main-bg').fadeOut()
        $('#fx').addClass('hidden')
        $('.wrapper').addClass('hidden')
        $('#bg').removeClass('hidden').addClass('v2')

        setTimeout(() => {
          window.location.replace(`https://${currentDomain}/?key=${tBackKey}&t9=${t9}&t2=${t2}&t1=${t1}`)
        }, 300)
      })
    })
  })
}

function getAnswers(val) {
  var result = ""

  if (Array.isArray(val)) {
    result += `<form action="">`
    val.forEach(v => {
      var vText = Array.isArray(v) ? v[0] : v

      if (v[1]['css'] === undefined) {
        result += `
                <label class="form-control">
                    <input type="checkbox" name="checkbox" />
                    ${vText}
                </label>
                `
      } else {
        result += `
                <label class="form-control ${v[1]['css']}">
                    <input type="checkbox" name="checkbox" />
                    ${vText}
                </label>
                `
      }
    })
    result += `</ul>`
  } else if (val && val.length > 1) {
    result = `<p>${val}</p>`
  }

  return result
}

Object.keys(QA).forEach((key, index) => {
  var result = ""
  var val = QA[key]

  try {
    if (passed_translations) {
      val = QA_translations[currentLanguage][key]
    }
  } catch {
    val = QA[key]
  }

  var section = document.createElement('section')
  section.className = `${key} box hidden`
  section.dataset.step = `${index + 1}`

  if (val.q[1].css === undefined) {
    result += `
            <div>${val.q[0]}</div>
            <div>${getAnswers(val.a)}</div>
        `
  } else {
    result += `
            <div class="img2 ${val.q[1].css}"><div class="scenes"></div></div>
            <div>${val.q[0]}</div>
            <div>${getAnswers(val.a)}</div>
        `
  }

  if (Array.isArray(val.a)) {
    result += `
        <div class="btn-wrapper">
            <a class="btn-yes action-btn btn" href="javascript:void(0)" data-i18n="generic-continue">CONTINUE</a>
        </div>
        `
  } else {
    result += `
        <div class="btn-wrapper">
        <a class="btn-yes action-btn btn skip" href="javascript:void(0)" data-i18n="generic-skip">SKIP</a>
            <a class="btn-yes action-btn btn" href="javascript:void(0)" data-i18n="generic-yes">YES</a>
        </div>
        `
  }


  section.innerHTML = result
  $("main section:last").before(section)
})

$('section.box').each((index, ele) => {
  var step = document.createElement('div')
  step.dataset.step = index + 1
  $('.steps').append(step)
})

try {
  if (passed_translations) {
    Object.keys(traslations[currentLanguage]).forEach((key) => {
      $(`[data-i18n=${key}]`).html(traslations[currentLanguage][key])
    })

    if (['ar', 'dv', 'he', 'fa', 'ks', 'ku', 'ps', 'ur', 'yi'].includes(currentLanguage)) {
      $('main').css('direction', 'rtl')
    }
  }
} catch {}

setTimeout(() => {
  var $imgs2 = $(".img")
  if($imgs2.hasClass('s3')) {
    $.when(
      $imgs2.animate({opacity: 0.1}, 50).promise(),
      $imgs2.animate({opacity: 1}, 50).promise(),
      $imgs2.addClass(`animate`).delay(7600).promise()
    )
  } else if(!(new URLSearchParams(window.location.search).get('_sv'))) {
    $.when(
      $imgs2.animate({opacity: 0.1}, 50).promise(),
      $imgs2.animate({opacity: 1}, 50).promise(),
      $imgs2.animate({opacity: 0.1}, 50).promise(),
      $imgs2.animate({opacity: 1}, 50).promise(),
      $imgs2.addClass(`animate`).delay(4000).promise()
      // $scenes.addClass(`scene2`).delay(1500).promise().done(function() {
      //   $scenes.removeClass(`scene2`).promise(),
      //   $scenes.addClass(`scene1`).delay(3000).promise()
      // })
    ).done(function() {
      $imgs2.removeClass(`animate`).promise()

      setTimeout(() => {
        $.when(
          $imgs2.animate({opacity: 0.1}, 50).promise(),
          $imgs2.animate({opacity: 1}, 50).promise(),
          $imgs2.addClass(`animate2`).delay(1500).promise()
          // $scenes.addClass(`scene2`).delay(1500).promise().done(function() {
          //   $scenes.removeClass(`scene2`).promise(),
          //   $scenes.addClass(`scene1`).delay(3000).promise()
          // })
        ).done(function() {
          $imgs2.removeClass(`animate2`).promise()
        })
      }, 3000)
    })
  }
}, 400)


var $aBtn = $('.action-btn')
$aBtn.on('click', function (e) {
  e.preventDefault()


  if ($(window).width() >= 769 && parseInt($(this).closest('.box')[0].dataset.step) == 5) {
    $('.main-bg').fadeOut()
    $('#fx')
      .fadeOut()
      .next()
      .removeClass('hidden')
  }

  $(this)
    .closest('.box')
    .addClass('hidden')
    .next()
    .removeClass('hidden')
    .addClass('puff-in-center')

  var current_step = $(this).closest(".box").next()[0].dataset.step
  var $step = $('.steps div[data-step=' + current_step + ']')
  $('.steps div').removeClass('step_no')
  $step.addClass('active step_no')
  // history.pushState({}, "", "#_q="+(Math.random() + 1).toString(36).substring(7))
 

  // if(current_step == 1){
  //   setTimeout(() => {
  //     var $img2 = $(".img2")
  //     var $scenes = $('.img2 .scenes')
  //     $.when(
  //       $img2.animate({opacity: 0.1}, 50).promise(),
  //       $img2.animate({opacity: 1}, 50).promise(),
  //       $img2.animate({opacity: 0.1}, 50).promise(),
  //       $img2.animate({opacity: 1}, 50).promise(),
  //       $scenes.addClass(`scene1`).delay(3000).promise()
  //       // $scenes.addClass(`scene2`).delay(1500).promise().done(function() {
  //       //   $scenes.removeClass(`scene2`).promise(),
  //       //   $scenes.addClass(`scene1`).delay(3000).promise()
  //       // })
  //     ).done(function() {
  //       $img2.animate({opacity: 0.1}, 50).promise(),
  //       $img2.animate({opacity: 1}, 150).promise(),     
  //       $scenes.fadeOut(150).promise()
  //     })
  //   }, 400)
  // }
})

